<template>
    <div>
        <div class="faq-container">
            <div class="faq-header">
                <p>{{$t('faq.subtitle')}}</p>
                <h1>{{$t('faq.title')}}</h1>
            </div>
            <ul class="faq-list">
                <li @click="r1 = !r1">
                    <div>01 <br> {{$t('faq.list[0].question')}}<span v-if="!r1">+</span> <span v-else>-</span>
                    </div>
                    <em v-if="r1">{{$t('faq.list[0].answer')}}</em>
                </li>
                <li @click="r2 = !r2">
                    <div>02 <br> {{$t('faq.list[1].question')}}<span v-if="!r2">+</span> <span v-else>-</span>
                    </div>
                    <em v-if="r2">{{$t('faq.list[1].answer')}}</em>
                </li>
                <li @click="r3 = !r3">
                    <div>03 <br> {{$t('faq.list[2].question')}}<span v-if="!r3">+</span> <span v-else>-</span>
                    </div>
                    <em v-if="r3">{{$t('faq.list[2].answer')}}</em>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Faqs',
    data() {
        return {
            r1: false,
            r2: false,
            r3: false,
        }
    },
    mounted() {
        this.toggleFaq();
    },
    methods: {
        toggleFaq() {
            const faqList = document.querySelectorAll('.faq-list div');
            faqList.forEach((faq) => {
                faq.addEventListener('click', () => {
                    faq.classList.toggle('active');
                });
            });
        },
    },
}
</script>
<style scoped>
.faq-container {
    width: 75%;
    margin: 20px auto;
    padding: 20px;
}

.faq-header {
    text-align: left;
    padding-bottom: 20px;
}

.faq-header p {
    color: #007bff;
    margin: 0;
    margin-bottom: 20px;
}

.faq-header h1 {
    color: #333;
    margin: 0;
    font-weight: 700;
}

.faq-header h1 {
    color: #333;
    margin: 0;
}

.faq-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.faq-list li {
    padding: 15px !important;
    text-align: left;
    cursor: pointer;
    border-radius: 20px !important;
}


.faq-list li .active {}

.faq-list li:last-child {
    border-bottom: none;
}

.faq-list div {
    text-decoration: none;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-list span {
    background: #007bff;
    color: white;
    border-radius: 50%;
    padding: 10px;
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 10px;
    font-weight: bold;
}
</style>