import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';


const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state: {
    idSession: null,
    idSessionOld: null,
    product: null,
    token: null,
  },
  getters: {
  },
  mutations: {
    setIdSession(state, idSession) {
      state.idSession = idSession
    },
    setIdSessionOld(state, idSessionOld) {
      state.idSessionOld = idSessionOld
    },
    setToken(state, token) {
      state.token = token
    },
    setProduct(state, product) {
      state.product = product
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
