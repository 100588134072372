<template>
    <img v-if="lang === 'es'" src="../assets/images/es.png" alt="flag">
    <img v-if="lang === 'fr'" src="../assets/images/fr.png" alt="flag">
    <img v-if="lang === 'en'" src="../assets/images/en.png" alt="flag">
</template>
<script>
export default {
    name: 'Flag',
    props: {
        lang: String
    }
}
</script>