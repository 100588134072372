<template>
    <img v-if="id === 1" src="../assets/images/1.png" alt="Asia">
    <img v-if="id === 2" src="../assets/images/2.png" alt="Europe">
    <img v-if="id === 3" src="../assets/images/3.png" alt="UK">
    <img v-if="id === 4" src="../assets/images/4.png" alt="North America">
    <img src="../assets/images/button.png" alt="start" class="bt">
</template>
<script>
export default {
    name: 'Map',
    props: {
        id: Number
    }
}
</script>
<style scoped>
.bt{
    position: absolute;
    right: 40px;
    width: 60px;
    top: 110px;
}
</style>