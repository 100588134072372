<template>
  <NavOptions />
  <router-view />
  <Footer />
</template>

<script>

import NavOptions from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavOptions,
    Footer
  }
}

</script>

<style lang="scss">
@import './assets/fonts/buenos-aires/stylesheet.css';




#app {
  font-family: 'Buenos Aires';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f4f8fc;
}

body {
  margin: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}



.line {
    position: absolute;
    right: 0;
    top: 34px;
}

.circle {
    position: absolute;
    top: -17px;
    left: -29px;
}

@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }

    .container {
        width: 98%;
    }

    .circle {
        display: none;
    }

    h2 {
        font-size: 25px;
        text-align: center;
    }

    .phone-image {
        width: 100%;
        text-align: center;
    }

    .phone-image img {
        width: 50%;
        height: auto;
        border-radius: 0px;
    }

    .estructure {
        width: 100%;
    }
}
</style>
