<template>
    <header class="header">
        <Icon class="logo" />
        <nav class="navegacion" :class="{ 'active': menuActive }">
            <span @click="toggleMenu(), goToSeccion('#home')">{{ $t('nav.home') }}</span>
            <span @click="toggleMenu(), goToSeccion('#about')">{{ $t('nav.about') }}</span>
            <span @click="toggleMenu(), goToSeccion('#regions')">{{ $t('nav.plans') }}</span>
            <span @click="toggleMenu(), goToSeccion('#coverage')">{{ $t('nav.coverage') }}</span>
            <span @click="toggleMenu(), goToSeccion('#contact')">{{ $t('nav.contact') }}</span>
        </nav>
        <div class="idioma-selector">
            <Flag :lang="lang" />
            <select v-model="lang">
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
            </select>
        </div>
        <!-- Botón de menú de hamburguesa -->
        <div class="hamburger" :class="{ 'active': menuActive }" @click="toggleMenu">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </header>
</template>

<script>
import Icon from '@/components/Icon.vue'
import Flag from '@/components/Flag.vue'
export default {
    name: 'Nav',
    data() {
        return {
            lang: 'en',
            menuActive: false,
        }
    },
    components: {
        Icon,
        Flag
    },
    // make navbar fixed on scroll
    mounted() {
        window.addEventListener('scroll', this.handleScroll)  
    },
    methods: {
        handleScroll() {
            const header = document.querySelector('.header')
            header.classList.toggle('sticky', window.scrollY > 300)
        },
        toggleMenu() {
            this.menuActive = !this.menuActive; // Alternar la visibilidad del menú

        },
        goToSeccion(section) {
            this.$router.push({ path: '/', hash: section })
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    watch: {
        lang(newVal) {
            this.$i18n.locale = newVal
        }
    }


}

</script>

<style>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f4f8fc;
}

.logo {
    max-width: 150px;
}

.navegacion span {
    margin: 0 10px;
    color: #333333;
    /* Ajusta el color según tu diseño */
    text-decoration: none;
    font-size: 16px;
    margin: 0 20px;
    cursor: pointer;
    /* Ajusta el tamaño de fuente según tu diseño */
}

.navegacion span:hover {
    text-decoration: underline;
    /* Estilo al pasar el ratón sobre el enlace */
}

.idioma-selector {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #267feb63;
}

.idioma-selector select {
    padding: 5px 10px;
    font-size: 16px;
    background-color: transparent;
    border: none;
}

.idioma-selector select:focus {
    outline: none;
}

.idioma-selector img {
    width: 24px;
    /* Ajusta según tu diseño */
    height: 24px;
    /* Ajusta según tu diseño */
    margin-right: 5px;
}

.idioma-selector select {
    padding: 5px 10px;
    font-size: 16px;
    /* Ajusta el tamaño de fuente según tu diseño */
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Ajusta el color de fondo según tu diseño */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Añade sombra al header */
    /* Ajusta el índice z según sea necesario */
}

/* Estilos por defecto que ya tienes, no requieren cambios */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f4f8fc;
    /* ...otros estilos */
}

/* Clases adicionales para el icono de hamburguesa */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    background-color: #333;
    margin: 5px 0;
    height: 3px;
    width: 25px;
}

.hamburger.active div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
    transition-duration: 500ms;
    
}

.hamburger.active div:nth-child(2) {
    opacity: 0;
}

.hamburger.active div:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -8px);
    transition-duration: 500ms;
}




.hamburger div:nth-child(1) {
    transform: rotate(0deg);
    transition-duration: 500ms;
    
}

.hamburger div:nth-child(2) {
    opacity: 1;
}

.hamburger div:nth-child(3) {
    transform: rotate(0deg);
    transition-duration: 500ms;
}




/* ...otros estilos existentes... */

/* Media queries para responsividad */
@media (max-width: 768px) {

    .idioma-selector {
        /* Ocultar el selector de idioma en tabletas y móviles */
    }

    /* Estilo para tabletas */
    .navegacion {
        display: none;
        /* Ocultar la navegación en tabletas y móviles */
    }

    .navegacion.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        /* Ajustar según la altura de tu header */
        left: 0;
        background-color: #d0e2f3;
        /* Ajustar según tu diseño */
        width: 100%;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    /* Estilos adicionales para el botón de menú de hamburguesa */
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .idioma-selector,
    .hamburger {
        margin: 0 10px;
    }
}

@media (max-width: 480px) {

    /* Estilo para móviles */
    .logo {
        max-width: 120px;
        /* Tamaño más pequeño para móviles */
    }
}
</style>