<template>
  <div v-if="error">
    <p>
      Something went wrong. Copy the following code and send it to support
      <span style="text-wrap: wrap;">
        {{ idSession }}
      </span>
    </p>
  </div>
  <div v-else>
    <div class="success-container" v-show="!loading">
      <h1>Thank You for Your Purchase!</h1>
      <p class="success-message">Your order has been placed successfully. Here are the details:</p>

      <div class="product-info">
        <p><strong>Order Payment:</strong> <br> </p>
        <span style="text-wrap: wrap;">
          {{ idSession }}
        </span>
        <h2>Esim {{ product.name }}</h2>
        <p>
          <strong>Price:</strong> ${{ product.price }} <br>
          We have sent a confirmation email to: <br> <strong>{{ response?.customer_details?.email }}</strong>
        </p>
      </div>

      <div class="qr-code">
        <img :src="qrCode" alt="QR Code">
      </div>

      <p>Please save the QR code for your records. It may be required for order verification.</p>
      <p>You can check your data plan details on our App</p>

      <div>
        <a href="https://play.google.com/store/apps/details?id=com.travela.xpacego" target="_blank"
          rel="noopener noreferrer">
          <img src="../assets/images/google.png" style="width: 200px; margin: 20px;">
        </a>
        <a href="https://apps.apple.com/us/app/trave-la/id6477246266" target="_blank" rel="noopener noreferrer">
          <img src="../assets/images/apple.png" style="width: 200px; margin: 20px;">
        </a>
      </div>
    </div>

    <div v-show="loading" style="height: 100vh;">
      <h1>Processing...</h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  computed: {
    ...mapState(['idSession', 'product', 'idSessionOld', 'token'])
  },
  data() {
    return {
      stripeSecretKey: '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',
      //stripeSecretKey: 'sk_test_51Mb8sIG3A04Axhp41OLQyLqVG2GbIpQVOoPpUbQ91JFZDr4EuvicbCSvaPYot5fwVGqMDReuhTJ7YoCYCksVtR8V00fgzQb9Ra',
      loading: false,
      response: null,
      qrCode: null,
      error: false
    };
  },
  mounted() {
    this.validateId();
  },
  methods: {
    ...mapMutations(['setIdSessionOld', 'setToken']),
    async getLocalIP() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
      } catch (error) {
        console.error('Error obteniendo la IP pública:', error);
        return null;
      }
    },

    async validateId() {
      if (this.idSession === null) {
        this.$router.push({ name: 'Home' });
        return;
      }
      this.loading = true;
      const options = {
        headers: {
          'Authorization': `Bearer ${this.stripeSecretKey}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      };
      axios.get('https://api.stripe.com/v1/checkout/sessions/' + this.idSession, options)
        .then(session => {
          console.log(session);
          this.response = session.data;
          if (this.response.payment_status === 'paid') {
            if (this.idSessionOld !== this.idSession) {
              this.createOrder(this.response.payment_status, this.response.id);
            } else {
              this.getContract(this.token);
            }
          } else {
            this.$router.push({ name: 'Home' });
          }
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    },

    createOrder(status, id) {
      console.log('entro');
      this.getLocalIP().then(ip => {
        console.log('La IP del dispositivo es:', ip);

        let formData = new FormData();
        formData.append("package_id", this.product.id);
        formData.append("name", "user");
        formData.append("last_name", "travela");
        formData.append("phone", "1234567890");
        formData.append("email", this.response?.customer_details?.email);
        formData.append("password", "12345678");
        formData.append("payment_gateway_id", 1);
        formData.append("ip", ip);
        formData.append("charge", id);
        formData.append("status_charge", status);

        let url = "https://xpacegotest.com/api/activate";
        const options = {
          headers: {
            'Authorization': `TFA2ak46OUduVmNtemFWS1hzVFUzR1NZUUc=`,
            'Accept': 'application/json',
          }
        };

        axios.post(url, formData, options)
          .then((response) => {
            console.log(response);
            this.setIdSessionOld(this.idSession);
            this.setToken(response.data.data.access_token);
            this.getContract(response.data.data.access_token);
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            this.error = true;
          });

      }).catch(error => {
        console.error('Error obteniendo la IP:', error);
      });
    },

    getContract(token) {
      let url = "https://xpacegotest.com/api/client/contract/all";
      let options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          var position = data.data.length - 1;
          this.getQR(data.data[position].id, token);
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    },

    getQR(id, token) {
      let url = "https://xpacegotest.com/api/client/contract/sim-detail?sim_id=" + id;
      let options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          this.qrCode = data.data.image_qr;
          this.sendEmail(data.data.image_qr);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    },

    sendEmail(qr) {
      let url = "http://xpacemovil.com/mails/api/send-email-esim";

      let formData = new FormData();
      formData.append("api_key", "veL2hP56U012p5xb6XJeupEhOuo696wC");
      formData.append("email", this.response.customer_details.email);
      formData.append("operador", "travela");
      formData.append("url_site", "https://www.trave.la/");
      formData.append("qr", this.qrCode);
      formData.append("id_purcharse", this.idSession);
      formData.append("logo", "http://xpacemovil.com/images/travela.png");
      formData.append("amount", this.product.price);

      let options = {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error("Error:", error));
    }
  },
};
</script>

<style scoped>
.success-container {
  width: 70%;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.success-message {
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

.qr-code {
  margin: auto;
  padding: 15px;
  background-color: #e9e9e9;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 20px;
}

.qr-code img {
  width: 350px;
  height: 350px;
}

@media (max-width: 768px) {
  .success-container {
    width: 98%;
  }
}
</style>
