<template>
    <div class="content">
        <div class="container">
            <div class="main-content">
                <div class="row">
                    <div class="phone-image">
                        <img src="../assets/images/mobile3.png" alt="Phone">
                    </div>
                    <div class="estructure">
                        <div style=" margin-bottom: 20px;" v-html="$t('news.title', { image: './img/circle.svg' })" />
                        <p class="mb-3"> {{ $t('news.subtitle') }} </p>


                        <div class="search-box">
                            <input type="text"
                                :placeholder="$t('news.placeholder')" ref="autocompleteInput" v-model="searchQuery" />
                            
                            <button @click="search"> {{ $t('news.button') }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<style scoped>
.search-box {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    padding: 6px;
    border-radius: 30px;
}

.search-box input {
    padding: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
}

.search-box input:focus {
    outline: none;
    border: none;
}

.search-box button {
    padding: 10px;
    background-color: #1a75ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 20px;
}
.content {
    background-image: url('../assets/images/bg.png');
    background-size: cover;
    padding: 20px;
}

.mb-3 {
    margin-bottom: 2rem !important;
}

.container {
    width: 90%;
    margin: 2em auto;
}

header {
    background-color: #007bff;
    color: white;
    padding: 10px 0;
    text-align: center;
}

.main-content {
    margin-top: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.features {
    list-style: none;
    padding: 0;
}

.features li {
    background: #efefef;
    margin-bottom: 10px;
    padding: 10px;
    border-left: 5px solid #007bff;
}

.features li .title {
    font-weight: bold;
}

.features li .description {
    margin-top: 5px;
}

h2 {
    font-weight: 700;
    color: #263238;
    font-size: 35px;
}

.estructure {
    padding: 2em;
    width: 60%;
    text-align: left;
}

.phone-image {
    text-align: center;
    width: 40%;
    position: relative;
}

.phone-image2 {
    text-align: center;
    width: 40%;
}


.phone-image img {
    width: 60%;
    height: auto;
    margin-top: 30px;
    border-radius: 30px;
    z-index: 10;
    position: relative;
}

.phone-image2 img {
    width: 100%;
    height: auto;
}

@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }

    .container {
        width: 98%;
    }

    .circle {
        display: none;
    }

    h2 {
        font-size: 25px;
        text-align: center;
    }

    .phone-image {
        width: 100%;
        text-align: center;
    }

    .phone-image img {
        width: 50%;
        height: auto;
        border-radius: 0px;
    }

    .estructure {
        width: 100%;
    }
}
</style>