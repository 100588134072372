<template>
    <div class="content" id="about">
        <div class="container">
            <div class="main-content">
                <div class="row">
                    <div class="phone-image">
                        <img src="../assets/images/luz.png" alt="Phone" style="width: 100%; position: absolute; left: 0; top: -72px; z-index: 9;">
                        <img src="../assets/images/mobile1.png" alt="Phone">
                    </div>
                    <div class="estructure">
                        <div style=" margin-bottom: 20px;" v-html="$t('about.title1', {image: './img/circle.svg'})"/>
                        <p class="mb-3"> {{ $t('about.text1') }} </p>

                        <div class="d-flex align-items-center flex-column flex-sm-row align-items-sm-start">
                            <div>
                                <img src="../assets/images/wifi.svg">
                            </div>
                            <div class="ms-3">
                                <h3>{{ $t('about.list1.title') }}</h3>
                                <p>{{ $t('about.list1.text') }}
                                </p>
                            </div>
                        </div>

                        <div class="d-flex align-items-center flex-column flex-sm-row align-items-sm-start">
                            <div>
                                <img src="../assets/images/simC.svg">
                            </div>
                            <div class="ms-3">
                                <h3>{{ $t('about.list2.title') }}</h3>
                                <p>{{ $t('about.list2.text') }}
                                </p>
                            </div>
                        </div>

                        <div class="d-flex align-items-center flex-column flex-sm-row align-items-sm-start">
                            <div>
                                <img src="../assets/images/mob.svg">
                            </div>
                            <div class="ms-3">
                                <h3>{{ $t('about.list3.title') }}</h3>
                                <p>{{ $t('about.list3.text') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="estructure">
                        <div style="position: relative; margin-bottom: 20px;" v-html="$t('about.title2')" />
                        <p class="mb-3">
                            {{ $t('about.text2') }}
                        </p>

                        <div class="d-flex align-items-center flex-column flex-sm-row align-items-sm-start">
                            <div>
                                <img src="../assets/images/global.svg">
                            </div>
                            <div class="ms-3">
                                <h3>{{ $t('about.list4.title') }}</h3>
                                <p>{{ $t('about.list4.text') }}
                                </p>
                            </div>
                        </div>

                        <div class="d-flex align-items-center flex-column flex-sm-row align-items-sm-start">
                            <div>
                                <img src="../assets/images/24.svg">
                            </div>
                            <div class="ms-3">
                                <h3>{{ $t('about.list5.title') }}</h3>
                                <p>{{ $t('about.list5.text') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="phone-image">
                        <img src="../assets/images/luz.png" alt="Phone" style="width: 100%; position: absolute; left: 0; top: -72px; z-index: 9;">
                        <img src="../assets/images/mobile2.png" alt="Phone">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<style scoped>
.content {
    background-color: #D9EAF7;
    padding: 20px;
}

.mb-3 {
    margin-bottom: 2rem !important;
}

.container {
    width: 90%;
    margin: 2em auto;
}

header {
    background-color: #007bff;
    color: white;
    padding: 10px 0;
    text-align: center;
}

.main-content {
    background: white;
    margin-top: 20px;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.features {
    list-style: none;
    padding: 0;
}

.features li {
    background: #efefef;
    margin-bottom: 10px;
    padding: 10px;
    border-left: 5px solid #007bff;
}

.features li .title {
    font-weight: bold;
}

.features li .description {
    margin-top: 5px;
}

h2 {
    font-weight: 700;
    color: #263238;
    font-size: 35px;
}

.estructure {
    padding: 2em;
    width: 60%;
    text-align: left;
}

.phone-image {
    text-align: center;
    width: 40%;
    position: relative;
}

.phone-image2 {
    text-align: center;
    width: 40%;
}


.phone-image img {
    width: 60%;
    height: auto;
    margin-top: 30px;
    border-radius: 30px;
    z-index: 10;
    position: relative;
}

.phone-image2 img {
    width: 100%;
    height: auto;
}

@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }

    .container {
        width: 98%;
    }

    .circle {
        display: none;
    }

    h2 {
        font-size: 25px;
        text-align: center;
    }

    .phone-image {
        width: 100%;
        text-align: center;
    }

    .phone-image img {
        width: 50%;
        height: auto;
        border-radius: 0px;
    }

    .estructure {
        width: 100%;
    }
}
</style>