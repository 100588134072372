<template>
    <div>
        <img src="../assets/images/test.png" style="">
    </div>
</template>
<style scoped>
    img {
        width: 85%;
    }

    div{
        margin-top: 5em;
        margin-bottom: 5em;
    }

    @media (max-width: 1024px) {
        img {
            width: 100%;
        }

        div{
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

</style>