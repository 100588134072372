<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="europeDataPlansModalLabel">Data Plans <br> <span class="span-c">
                        {{ data?.name }}</span></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <img src="../assets/images/left.svg" class="btn-back" v-if="step == 2" @click="step = 1">
                <Mapa :id="data.region_id"> </Mapa>
            </div>
            <div class="modal-body">
                <div v-show="step == 1">
                    <div class="d-flex justify-content-between">
                        <h2>E Sims in {{ data.name }}</h2>
                        <img src="../assets/images/unlimited.svg" alt="" srcset="">
                    </div>
                    <!-- Plan Item -->
                    <div class="d-flex align-items-center justify-content-between my-2 item" v-for="(item, i) in plans"
                        @click="changeStep(item)">
                        <div class="d-flex align-items-center ">
                            <img src="../assets/images/sim.svg" alt="" srcset="">
                            <!-- Bootstrap Icon, make sure to include Bootstrap Icons if you're using them -->
                            <div class="description">
                                <div>{{ item.name }}</div>
                                <div class="text-muted">Valid for {{ item.duration_days }} Days</div>
                                <div class="price">${{ item.price }} USD</div>
                            </div>
                        </div>
                        <img src="../assets/images/btn.svg" style="width: 70px;">
                    </div>
                </div>
                <div v-show="step == 2">
                    <div class="d-flex align-items-center justify-content-between my-2 item">
                        <div class="d-flex align-items-center ">
                            <img src="../assets/images/sim.svg" alt="" srcset="">
                            <!-- Bootstrap Icon, make sure to include Bootstrap Icons if you're using them -->
                            <div class="description">
                                <div>{{ itemSelected.name }}</div>
                                <div class="text-muted">Valid for {{ itemSelected.duration_days }} Days</div>
                                <div class="price">${{ itemSelected.price }} USD</div>
                            </div>
                        </div>
                        <img src="../assets/images/check.svg" style="width: 30px;">
                    </div>
                    <div class="d-flex justify-content-between flex-column summary">
                        <h2>Plan Summary</h2>
                        <div class="d-flex justify-content-between">
                            <p class="label">Plan</p>
                            <p class="product">{{ itemSelected.name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="label">Speed</p>
                            <p class="product"> <img src="../assets/images/speed.svg"> 5G/4G</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="label">Service</p>
                            <p class="product"> <img src="../assets/images/data.svg"> Data Only</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="label">Duration</p>
                            <p class="product">{{ itemSelected.duration_days }} Days</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="label">Add-on-Available</p>
                            <p class="product">Yes</p>
                        </div>
                        <button class="btn btn-primary" @click="createProduct()" v-if="!loading">
                            Pay ${{ itemSelected.price }} USD
                        </button>
                        <button class="btn btn-primary" v-else>
                            loading...
                        </button>
                        <img src="../assets/images/label.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
import Mapa from '@/components/Map.vue'
export default {
    name: 'EuropeDataPlansModal',
    components: {
        Mapa
    },
    data() {
        return {
            plans: [],
            error: null,
            step: 1,
            itemSelected: {},
            loading: false,
            lineItems: [
                {
                    price: "price_1OymJVG3A04Axhp44PgyoSCk", // The id of the one-time price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
            successURL: 'https://trave.la/success',
            cancelURL: 'https://trave.la/',
            //successURL: 'http://localhost:8080/success',
            //cancelURL: 'http://localhost:8080/',
            publishableKey: 'pk_live_51Oags4Hcepp39PTgDTmt6C5Rz8ZLAx1TCgDdTz2EraX6OddVOKLzi9mmSRrDQVrbaUqf2gEn6u6cZNiXWVBh6We200d9Y2AyOz',
            stripeSecretKey: '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',
            //stripeSecretKey: 'sk_test_51Mb8sIG3A04Axhp41OLQyLqVG2GbIpQVOoPpUbQ91JFZDr4EuvicbCSvaPYot5fwVGqMDReuhTJ7YoCYCksVtR8V00fgzQb9Ra',

        };
    },
    props: {
        data: Object,
        type: String,
    },
    methods: {
        ...mapMutations(['setIdSession', 'setProduct']),
        getPlans(id) {
            console.log('entro no se que pase');
            this.step = 1;
            this.plans = [];
            const options = {
                headers: {
                    'Authorization': 'TFA2ak46OUduVmNtemFWS1hzVFUzR1NZUUc=',
                    'Accept': 'application/json',
                }
            };
            axios.get(`https://xpacegotest.com/api/regions/${id}/packages`, options)
                .then(response => {
                    this.plans = response.data.data;
                })
                .catch(error => {
                    this.error = error;
                    console.error('There was an error fetching the countries:', error);
                });
        },
        changeStep(item) {
            console.log(item);
            this.itemSelected = item;
            this.step = 2;
        },
        submit() {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        },
        createProduct() {
            this.loading = true;
            const options = {
                headers: {
                    'Authorization': `Bearer ${this.stripeSecretKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            axios.post('https://api.stripe.com/v1/products', {
                name: "Esim Trave.la " + this.itemSelected.name,

            }, options)
                .then(result => {
                    console.log(result.data);
                    axios.post('https://api.stripe.com/v1/prices', {
                        currency: 'usd',
                        unit_amount: parseFloat(this.itemSelected.price) * 100,
                        product: result.data.id,
                    }, options)
                        .then(product => {
                            console.log(product.data);
                            this.lineItems[0].price = product.data.id;
                            // this.$refs.checkoutRef.redirectToCheckout();
                            axios.post('https://api.stripe.com/v1/checkout/sessions', {
                                payment_method_types: ['card'],
                                line_items: this.lineItems,
                                mode: 'payment',
                                success_url: this.successURL,
                                cancel_url: this.cancelURL,
                            }, options)
                                .then(session => {
                                    this.setIdSession(session.data.id);
                                    this.setProduct(this.itemSelected);
                                    window.location.href = session.data.url;
                                    this.loading = false;

                                })
                                .catch(error => {
                                    console.error(error);
                                    this.loading = false;
                                });
                        })
                        .catch(error => {
                            console.error(error);
                            this.loading = false;
                        });
                })
                .catch(error => {
                    console.error(error);
                    this.loading = false;
                });
        },


    },
    // Your component's logic here
}
</script>

<style scoped>
/* Agrega aquí tus estilos personalizados */
.modal-header {
    background: linear-gradient(180deg, #2680EB, #3b70d22e);
    border-radius: 30px 30px 0 0;
    padding: 1rem 0;
}

.btn-primary {
    background: #2680EB;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 20px 20px;
    font-size: 26px;
    font-weight: 700;
    margin-top: 1em;
}

.text-muted {
    font-size: 12px;
    color: #BFC6CC;
    font-weight: 200;

}

.summary {
    padding: 1em;
}

.btn-back {
    position: absolute;
    top: 18px;
    left: 10px;

}

.price {
    font-size: 16px;
    font-weight: 700;
    color: #2680EB;

}

.description {
    text-align: left;
    margin-left: 10px;
}

h2 {
    font-size: 22px;
    font-weight: 700;
    text-align: left;
}

.item {
    background: white;
    border: 0px solid #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.modal-content {
    border-radius: 30px;
    border: none;
}

.btn-close {
    color: #fff !important;
    position: absolute;
    right: 11px;
    top: 13px;

}

.modal-title {
    text-align: left;
    color: #fff;
    line-height: 30px;
    font-size: 28px;
    margin-left: 20px;
}

.span-c {
    font-size: 42px;
    font-weight: 700;
}

.modal-body {
    background: #fafafa;
    border-radius: 0 0 30px 30px;
}

.label {
    font-size: 16px;
    color: #9B9C9D;
    font-weight: 200;
}

.product {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}
</style>