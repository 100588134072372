<template>
    <section class="hero" id="home">
        <div class="hero-content">
            <div class="title">
                <h1>E-Sim</h1>
                <img src="../assets/images/X.svg" alt="X">
                <h1> {{ $t('travel') }}</h1>
            </div>
            <h1>{{ $t('global') }}</h1>
            <p>{{ $t('experience') }}</p>

            <p class="label">{{ $t('label') }}</p>
            <div class="search-box">
                <img src="../assets/images/search.svg" alt="X"><input type="text" :placeholder="$t('search')"
                    ref="autocompleteInput" v-model="searchQuery" />
                <img src="../assets/images/fly.svg" alt="X">
            </div>
        </div>
        <div class="hero-image">
            <img src="../assets/images/home.png" alt="E-Sim X Travel App">
        </div>
    </section>

    <button type="button" data-bs-toggle="modal" data-bs-target="#chema2" ref="btnI" style="display: none;">
    </button>
    <Modal :data="contry" type="contry" ref="modal2" />
</template>

<script>
import axios from 'axios';
import Modal from './Modal3.vue';
export default {
    data() {
        return {
            searchQuery: '',
            autocomplete: null,
            countries: [],
            contry: {},
        }
    },
    components: {
        Modal
    },
    mounted() {
        // Initialize the autocomplete
        this.initAutocomplete();
    },
    methods: {
        initAutocomplete() {
            // Initialize the autocomplete
            const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, {
                types: ['(cities)']
            });

            // Add listener to get the selected place
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                console.log(place);
                // get the contry code
                const countryCode = place.address_components.find(c => c.types.includes('country')).short_name;
                this.fetchCountries(countryCode);
                console.log(countryCode);
            });
        },

        fetchCountries(countryCode) {
            const options = {
                headers: {
                    'Authorization': 'TFA2ak46OUduVmNtemFWS1hzVFUzR1NZUUc=',
                    'Accept': 'application/json',
                }
            };

            axios.get('https://xpacegotest.com/api/countrys', options)
                .then(response => {
                    this.countries = response.data.data;
                    this.contry = this.countries.find(c => c.code === countryCode);
                    console.log(this.contry);

                    // validate if the contry exist
                    if (this.contry) {
                        this.$refs.modal2.getPlans(this.contry.region_id);
                        this.$refs.btnI.click();
                        this.searchQuery = '';
                    } else {
                        console.log('Contry not found');
                        this.searchQuery = 'Contry not found';
                    }
                })
                .catch(error => {
                    this.error = error;
                    console.error('There was an error fetching the countries:', error);
                });
        },
    }

}
</script>

<style scoped>
.hero {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 50px;
    /* Replace with the actual path to your background image */
}

.label {
    font-weight: 800;
    margin-top: 30px !important;
}

.hero-content {
    text-align: left;
}

.title {
    display: flex;
    align-items: center;
    justify-content: start;
}

.title img {
    width: 90px;
    height: 90px;
    margin: 0 10px;
}

.hero-content h1 {
    color: #263238;
    font-size: 5em;
    margin: 0 15px;
    padding: 0;
    text-wrap: nowrap;
}

.hero-content p {
    color: #003366;
    margin: 10px 0;
    font-size: 1.3em;
}

.search-box {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    padding: 6px;
    border-radius: 20px;
}

.search-box input {
    padding: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
}

.search-box input:focus {
    outline: none;
    border: none;
}

.search-box button {
    padding: 10px;
    background-color: #1a75ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.hero-image {
    max-width: 50%;
    height: auto;
    margin-top: -80px;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* For mobile responsiveness */
@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .hero-content h1 {
        font-size: 2em;
        margin: 0 1px;
    }

    .hero-content p {
        font-size: .8em;
    }

    .title {
        flex-wrap: nowrap;
    }

    .title img {
        width: 30px;
        height: 30px;
    }

    .hero-image {
        max-width: 400px;
    }

    .hero-image {
        margin-top: 20px;
    }
}
</style>