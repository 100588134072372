import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: AboutView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Si hay una posición guardada, usa eso, útil para el comportamiento de "volver"
    if (savedPosition) {
      return savedPosition;
    } else {
      // Comprueba si la ruta tiene un hash, indicando que queremos desplazarnos a un ancla
      if (to.hash) {
        return new Promise((resolve) => {
          // Usamos setTimeout para manejar transiciones o renderizados asíncronos
          setTimeout(() => {
            // El selector to.hash es usado para encontrar el elemento, y behavior 'smooth' para un desplazamiento suave
            resolve({
              el: to.hash,
              behavior: 'smooth',
              top: 140,
            });
          }, 10); // Un ligero retraso
        });
      }
      // Por defecto, desplaza al inicio de la página
      return { left: 0, top: 0 };
    }
  },
});

export default router
