<template>
  <div>
    <Home />
    <Compatibility />
    <Regions />
    <About />
    <Testimonials />
    <Faqs />
    <News />
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'
import Compatibility from '@/components/Compatibility.vue'
import Regions from '@/components/Regions.vue'
import About from '@/components/About.vue'
import Testimonials from '@/components/Testimonials.vue'
import Faqs from '@/components/Faqs.vue'
import News from '@/components/News.vue'

export default {
  name: 'HomeView',
  components: {
    Home,
    Compatibility,
    Regions,
    About,
    Testimonials,
    Faqs,
    News
  },
}
</script>
