<template>
    <div class="modal fade" id="europeDataPlansModal" tabindex="-1" aria-labelledby="europeDataPlansModalLabel"
        aria-hidden="true">
        <Content :data="data" :type="type" ref="content"/>
    </div>
</template>

<script>
import Content from '@/components/ModalContent.vue'
export default {
    name: 'EuropeDataPlansModal',
    components: {
        Content
    },
    data() {
        return {};
    },
    props: {
        data: Object,
        type: String,
    },
    methods: {
        getPlans(id) {
            this.$refs.content.getPlans(id);
        },
    },
}
</script>
