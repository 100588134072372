<template>
    <footer class="footer" id="contact">
        <div class="d-flex flex-column">
            <Logo class="logo" />
            <nav class="navegacion" :class="{ 'active': menuActive }">
                <span @click="toggleMenu(), goToSeccion('#home')">{{ $t('nav.home') }}</span>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#447FBB" />
                </svg>
                <span @click="toggleMenu(), goToSeccion('#about')">{{ $t('nav.about') }}</span>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#447FBB" />
                </svg>
                <span @click="toggleMenu(), goToSeccion('#regions')">{{ $t('nav.plans') }}</span>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#447FBB" />
                </svg>
                <span @click="toggleMenu(), goToSeccion('#coverage')">{{ $t('nav.coverage') }}</span>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#447FBB" />
                </svg>
                <span @click="toggleMenu(), goToSeccion('')">{{ $t('nav.contact') }}</span>
            </nav>


            <div>
                <a href="https://play.google.com/store/apps/details?id=com.travela.xpacego" target="_blank"
                    rel="noopener noreferrer">
                    <img src="../assets/images/google.png" style="width: 200px; margin: 20px;">
                </a>
                <a href="https://apps.apple.com/us/app/trave-la/id6477246266" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/images/apple.png" style="width: 200px; margin: 20px;">
                </a>
            </div>
        </div>
        <div class="location">
            <div class="chip">
                Location
            </div>
            The Engine Room, 2nd & 3rd Floor<br>
            Power Station<br>
            London UK, SW1 BB2
        </div>
        <div class="contact">
            <div class="chip">
                Contact
            </div>
            <a href="mailto:info@trave-laworld.com">info@trave-laworld.com</a><br>
            +1 866-337-OPWG (0374)
        </div>
    </footer>
    <div class="bottom-bar">
        © 2024 Trave.la. All rights reserved
    </div>

</template>
<script>
import Logo from '@/components/Icon.vue'

export default {
    name: 'Footer',
    components: {
        Logo
    },
    methods: {
        toggleMenu() {
            this.menuActive = !this.menuActive; // Alternar la visibilidad del menú

        },
        goToSeccion(section) {
            this.$router.push({ path: '/', hash: section })
        }
    }
}

</script>
<style scoped>
.chip {
    background-color: #f1f1f1;
    color: #333;
    padding: 5px 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 1px solid #000000;

}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 5em 2em;
    background-color: #f1f1f1;
    font-family: Arial, sans-serif;
}

.footer .logo {
    font-size: 2em;
    color: #007bff;
}

nav {
    padding: 0;
}

.navegacion span {
    margin: 0 6px !important;
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    margin: 0 20px;
    cursor: pointer;
}

.footer .menu {
    list-style: none;
    display: flex;
    gap: 10px;
}

.footer .menu a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.footer .location,
.footer .contact {
    font-size: 1em;
    text-align: center;
}

.footer .contact a {
    text-decoration: none;
    margin: 0 5px;
}

.footer .social-icons {
    font-size: 1.2em;
}

.footer .social-icons a {
    text-decoration: none;
    margin: 0 5px;
}

.footer .bottom-bar {
    text-align: center;
    margin-top: 20px;
    font-size: 0.8em;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer .menu {
        margin-top: 20px;
    }

    .footer .location,
    .footer .contact {
        margin-top: 20px;
    }
}
</style>