<template>
    <section class="plans" id="coverage">
        <header>
            <h2>{{ $t('coverage.title') }}</h2>
            <h1>{{ $t('coverage.subtitle') }}</h1>
            <p>{{ $t('coverage.text') }}</p>
        </header>
        <div class="plan-cards">
            <div class="plan-cards-div">
                <div v-for="(contry, i) in countries" :key="i">
                    <button  v-if="i < 6" type="button"  data-bs-toggle="modal"
                        data-bs-target="#europeDataPlansModal" @click="openModal(contry)" >
                        <div class="plan">
                            <img :src="contry.flag" :alt="contry.code" class="flag">
                            <div class="plan-info">
                                <div class="data-amount">{{ contry.name }}</div>
                                <div class="duration">{{ getRegionById(contry.region_id) }}</div>
                                <div class="price">{{ contry.code }}</div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <Modal :data="contry" type="contry" ref="moda" idModal="europeDataPlansModal"/>
    </section>
</template>
<script>
import axios from 'axios';
import Modal from './Modal.vue';

export default {
    name: 'CountryFetcher',
    components: {
        Modal,
    },
    data() {
        return {
            countries: [],
            regions: [],
            error: null,
            contry: {},
        };
    },
    methods: {
        fetchCountries() {
            const options = {
                headers: {
                    'Authorization': 'TFA2ak46OUduVmNtemFWS1hzVFUzR1NZUUc=',
                    'Accept': 'application/json',
                }
            };

            axios.get('https://xpacegotest.com/api/countrys', options)
                .then(response => {
                    this.countries = response.data.data;
                })
                .catch(error => {
                    this.error = error;
                    console.error('There was an error fetching the countries:', error);
                });
        },
        fetchRegion() {
            console.log('https://xpacegotest.com/api/regions');

            const options = {
                headers: {
                    'Authorization': 'TFA2ak46OUduVmNtemFWS1hzVFUzR1NZUUc=',
                    'Accept': 'application/json',
                }
            };

            axios.get('https://xpacegotest.com/api/regions', options)
                .then(response => {
                    this.regions = response.data.data;
                })
                .catch(error => {
                    this.error = error;
                    console.error('There was an error fetching the countries:', error);
                });

        },
        getRegionById(id) {
            return this.regions.find(region => region.id === id)?.name;
        },
        openModal(contry) {
            console.log('Opening modal for country with id:', contry);
            this.contry = contry;
            this.contry.region = this.regions.find(region => region.id === contry.region_id);
            this.$refs.moda.getPlans(this.contry.region.id);
        }
    },
    mounted() {
        this.fetchCountries();
        this.fetchRegion();
    },
}
</script>

<style scoped>
h2 {
    color: #2680EB !important;
    font-size: 2em !important;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}

h1 {
    color: #000 !important;
    font-size: 3em !important;
}

p {
    color: #666 !important;
    font-size: 1.5em !important;
}

.plans {
    padding: 5rem 0;
    background: linear-gradient(180deg, #267feb1b 80%, #a3e79c28);
}

.plans header {
    text-align: center;
    margin-bottom: 2rem;
}

.plans header h2 {
    color: #555;
    font-size: 1rem;
}

.plans header h1 {
    color: #000;
    font-size: 2rem;
    margin: 0.5rem 0;
}

.plans header p {
    color: #666;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.plan-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 100%;
}

.plan-cards-div {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
}

.plan-info {
    text-align: left;
    text-wrap: wrap;
    font-size: 20px;
    margin-left: 10px;
}

.plan {
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    margin: 1.2rem;
    width: 274px;
    min-width: 274px;
    background-image: url('../assets/images/country-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* Adjust as necessary */
}

.flag {
    width: 60px;
    height: 60px;
}


.duration {
    color: #555;
    font-size: 13px;
}

.data-amount {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    text-wrap: wrap;
}

.price {
    font-size: 12px !important;
    color: #000;
    font-weight: 400;
}

.select-plan {
    border: none;
    background: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.select-plan:hover {
    background: #0056b3;
}

@media (max-width: 1024px) {
    h2 {
        font-size: 1.5em !important;

    }

    h1 {
        font-size: 2.5em !important;
    }

    p {
        font-size: 1.25em !important;
    }

    .plan-cards {
        flex-direction: column;
        align-items: center;
    }

    .plan {
        width: 80%;
        max-width: 300px;
    }

    .flag {
        width: 50px;
    }
}
</style>