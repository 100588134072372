import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import VueStripeElementsPlus from 'vue-stripe-elements-plus';

import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Importar archivos de idioma
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";

const i18n = createI18n({
  locale: "en", // Idioma por defecto
  fallbackLocale: "en", // Se utiliza si el idioma actual no está disponible
  messages: {
    en,
    es,
    fr,
  },
});

createApp(App).use(store).use(router).use(i18n).use(
  VueStripeElementsPlus, {
    stripe: {
      apiKey: 'pk_live_51Oags4Hcepp39PTgDTmt6C5Rz8ZLAx1TCgDdTz2EraX6OddVOKLzi9mmSRrDQVrbaUqf2gEn6u6cZNiXWVBh6We200d9Y2AyOz'
    }
  }
).mount("#app");
