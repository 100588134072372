<template>
    <img alt="Travela logo" src="../assets/images/icon.svg" style="width: 100%;" @click="goHome()">
</template>
<script>
export default {
    methods: {
        goHome() {
            this.$router.push({ name: 'home' });
        }
    }
}
</script>